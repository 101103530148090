import { css } from "@emotion/react";
import { Avatar } from "./icons";
import { botSpaik } from "./icons";

export interface MinimizedWindowProps {
  minimized: boolean;
  onClick: () => void;
  plataformId: number;
}

const MinimizedWindow = ({
  minimized,
  onClick,
  plataformId
}: MinimizedWindowProps) => (
  <div
    css={css`
      bottom: 10px;
      display: ${minimized ? "flex" : "none"};
      flex-direction: row;
      position: fixed;
      right: 10px;

      @media (max-width: 800px) {
        display: none;
      }
    `}
    className="imbot-chat-minimized"
  >
    <div
      css={css`
        align-items: center;
        cursor: pointer;
        display: flex;
      `}
      onClick={onClick}
    >
      {plataformId === 3 || plataformId === 4 ? (
        <img
          src={botSpaik}
          style={{ objectFit: "contain", width: "60px", height: "50px" }}
        />
      ) : (
        <img src={Avatar} width={46} height={46} />
      )}
    </div>
  </div>
);

export default MinimizedWindow;
