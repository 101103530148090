import { css } from "@emotion/react";
import { useEffect, useState } from "react";
import {
  PoppinsItalic,
  PoppinsRegular,
  PoppinsSemiBold,
  PoppinsSemiBoldItalic
} from "./fonts";
import MinimizedWindow from "./minimized-window";
import Window from "./window";

declare global {
  interface Window {
    icwToken: string;
  }
}

export interface BotInfoInteraction {
  interaction: string;
  position: number;
}

export interface BotInfo {
  plataformId: number;
  avatarColor: string;
  chatColor: string;
  name: string;
  target: string;
  nameOnStart: boolean;
  phoneOnStart: boolean;
  emailOnStart: boolean;
  interactions: BotInfoInteraction[];
}

import "primereact/resources/primereact.min.css";
import "primereact/resources/themes/bootstrap4-light-blue/theme.css";
import "/node_modules/flag-icons/css/flag-icons.min.css";

const Chat = () => {
  const [minimized, setMinimized] = useState(true);
  const [botInfo, setBotInfo] = useState<BotInfo>();

  useEffect(() => {
    const run = async () => {
      const response = await fetch(
        process.env.NODE_ENV === "development"
          ? `/bots/botinfo/${process.env.BOT_TOKEN}`
          : `https://${process.env.CHAT_URL}/bots/botinfo/${window.icwToken}`
      );

      const botInfo = await response.json();

      setBotInfo(botInfo);
    };

    run();
  }, []);

  if (!botInfo) {
    return null;
  }

  return (
    <div
      className="imbot-chat"
      css={css`
        box-sizing: border-box;
        background-color: #ffffff;
        color: #212529;
        font-family: "Poppins", sans-serif;
        font-size: 14px;
        font-weight: 400;
        line-height: 1.5;

        &:before,
        &:after {
          box-sizing: inherit;
        }

        @font-face {
          font-family: "Poppins";
          src: url("${PoppinsRegular}") format("truetype");
          font-weight: normal;
          font-style: normal;
          font-display: swap;
        }

        @font-face {
          font-family: "Poppins";
          src: url("${PoppinsItalic}") format("truetype");
          font-weight: normal;
          font-style: italic;
          font-display: swap;
        }

        @font-face {
          font-family: "Poppins";
          src: url("${PoppinsSemiBold}") format("truetype");
          font-weight: 600;
          font-style: normal;
          font-display: swap;
        }

        @font-face {
          font-family: "Poppins";
          src: url("${PoppinsSemiBoldItalic}") format("truetype");
          font-weight: 600;
          font-style: italic;
          font-display: swap;
        }

        input:focus,
        textarea:focus,
        button:focus,
        div:focus,
        span:focus {
          outline: 0;
        }

        a {
          color: #046af3;
        }

        .p-component {
          font-family: "Poppins", sans-serif;
          font-size: 14px;
          line-height: 1.5;
        }

        .p-inputtext {
          color: #212529;
          font-family: "Poppins", sans-serif;
          font-size: 14px;
          font-weight: 400;
          line-height: 1.5;
        }

        .p-button.p-button-icon-only {
          padding: 4px 0;
        }

        .p-inputtext.p-component.p-invalid,
        .p-inputwrapper.p-component.p-invalid > .p-inputtext {
          border-color: #dc3545;
        }

        .p-inputtext.p-component.p-invalid:focus,
        .p-inputwrapper.p-component.p-invalid.p-inputwrapper-focus
          > .p-inputtext {
          border-color: #dc3545;
          box-shadow: 0 0 0 4px rgb(220 53 69 / 25%);
        }

        canvas {
          max-height: 15px !important;
          height: 15px !important;
        }
      `}
    >
      <MinimizedWindow
        onClick={() => setMinimized(false)}
        minimized={minimized}
        plataformId={botInfo.plataformId}
      />

      <Window
        onClose={() => setMinimized(true)}
        botInfo={botInfo}
        minimized={minimized}
      />
    </div>
  );
};

export default Chat;
